import type { SortOrder } from '@angloeastern/react-library';
import { capitalize } from '@angloeastern/react-library';

export const capitalizeAll = (str: string) => {
  if (str && str.trim() !== '') {
    const splitted = str.split(' ').map((txt: string) => {
      return capitalize(txt.toLocaleLowerCase());
    });
    return splitted.join(' ');
  }
  return '';
};

export const getPageSlice = (
  data: Array<any>,
  page: number,
  perPage: number
) => {
  const dataLength = data.length;
  const sliceStart: number = (page - 1) * perPage;
  let sliceEnd: number = sliceStart + perPage;
  if (sliceEnd > dataLength - 1) {
    sliceEnd = dataLength;
  }
  return { sliceStart, sliceEnd };
};

export const sortFiles = (
  data: Array<any>,
  sortBy: string,
  sortOrder: SortOrder
) => {
  return data.sort((a, b) => {
    if (sortBy && a[sortBy] && b[sortBy]) {
      if (a[sortBy] === b[sortBy]) {
        return 0;
      } else if (sortOrder === 'ASC') {
        return a[sortBy] < b[sortBy] ? -1 : 1;
      } else {
        return a[sortBy] > b[sortBy] ? -1 : 1;
      }
    }
    return 0;
  });
};

export const highlightSearchMatch = (
  term: string,
  data: Array<any>,
  fields: Array<string>
): Array<any> => {
  const query = term.trim();
  if (!query) return [...data]; // Return original data if no search term

  const pattern = new RegExp(`(${query})`, 'i'); // Case-insensitive match
  const queryLowercased = query.toLowerCase();

  return data.map((item) => {
    const itemCopy = { ...item };

    fields.forEach((field) => {
      const fieldValue = item[field];
      if (fieldValue && fieldValue.toLowerCase().includes(queryLowercased)) {
        itemCopy[field] = fieldValue.replace(pattern, '<span>$1</span>');
      }
    });

    return itemCopy;
  });
};

export const getDefaultLanguage = () => {
  const { REACT_APP_DEFAULT_LANGUAGE } = process.env;
  return REACT_APP_DEFAULT_LANGUAGE || 'en';
};

export const getSupportedLanguages = () => {
  const { REACT_APP_SUPPORTED_LANGUAGES } = process.env;
  const defaultLang = getDefaultLanguage();
  return REACT_APP_SUPPORTED_LANGUAGES
    ? REACT_APP_SUPPORTED_LANGUAGES.split('|')
    : [defaultLang];
};

export const getCurrentLanguage = (language: string) => {
  const supported = getSupportedLanguages();
  const defaultLang = getDefaultLanguage();
  const loweredLanguage = language.toLowerCase();
  return supported.indexOf(loweredLanguage) >= 0
    ? loweredLanguage
    : defaultLang;
};

export const sortObject = (obj: any) => {
  return Object.keys(obj)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    .reduce((result: any, key: string) => {
      result[key] = obj[key];
      return result;
    }, {});
};

export const deepCopy = (data: any) => {
  const result = data ? JSON.parse(JSON.stringify(data)) : data;
  return result;
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number
): ((...args: Parameters<T>) => Promise<ReturnType<T>>) => {
  let timeout: ReturnType<typeof setTimeout> | null;
  let rejecter: (reason?: any) => void;

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    if (timeout) {
      clearTimeout(timeout);
      if (rejecter)
        rejecter({
          name: 'DebounceCancelled',
          message: 'Debounced function call was cancelled'
        });
    }

    return new Promise((resolve, reject) => {
      timeout = setTimeout(async () => {
        timeout = null;
        try {
          const result = await func(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, wait);
      rejecter = reject;
    });
  };
};

export const groupArray = <T, K extends keyof any>(
  array: T[],
  keyFn: (item: T) => K
): Record<K, T[]> => {
  return array.reduce(
    (result, item) => {
      const key = keyFn(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    },
    {} as Record<K, T[]>
  );
};

export const stripTags = (str: string) => {
  let result = '';
  let inTag = false;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (char === '<') {
      inTag = true;
      continue;
    }
    if (char === '>') {
      inTag = false;
      continue;
    }
    if (!inTag) {
      result += char;
    }
  }
  return result;
};

export const randomString = () => {
  return Math.random().toString(36).substring(7);
};
