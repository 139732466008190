import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
  download as doDownload,
  useFileViewer,
  usePdfViewer
} from '@angloeastern/react-library';
import { fetchUrl } from '@api/Common';
import { urlCheck } from '@api/callAPI';
import { FileTypes } from '@components/index';
import { useConfig } from '@context/Config';
import useDownload from '@hooks/useDownload';
import useFileTypes from '@hooks/useFileTypes';

const initState = { checked: false, valid: false };
const Context = createContext<any>({});

export const Provider: React.FC<{
  checkUrl?: boolean;
  showFilename?: boolean;
  showCrossmark?: boolean;
  showDownload?: boolean;
  showFullscreenButton?: boolean;
  showIframeToolbar?: boolean;
  children: ReactNode;
  fullscreen: boolean;
}> = ({
  checkUrl = false,
  showFilename = false,
  showCrossmark = false,
  showDownload = false,
  showFullscreenButton = false,
  showIframeToolbar = false,
  children,
  fullscreen
}) => {
  const { state, closeViewer } = useFileViewer();
  const { fileDownload } = useDownload();
  const { canPreview, getExtension, getFileTypeFromMime, isVideo } =
    useFileTypes();
  const { setSelected } = usePdfViewer();
  const { deviceCheck } = useConfig();

  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [validCache, setValidCache] = useState<string[]>([]);
  const [linkState, setLinkState] = useState(initState);
  const [fileType, setFileType] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(fullscreen);

  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;

  const toggleFullScreen = () => setIsFullScreen((prev) => !prev);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setLoading(false);
    setNumPages(numPages);
  };

  const handleLoad = () => setLoading(false);

  const isVerified = () => {
    if (!validCache.length || !state.selected) return false;
    const { url, dlUrl } = state.selected;
    return validCache.includes(url) && (!dlUrl || validCache.includes(dlUrl));
  };

  const addToValidCache = () => {
    if (state.selected) {
      const { url, dlUrl } = state.selected;
      setValidCache((cache) => [
        ...cache,
        url,
        ...(dlUrl && dlUrl !== url ? [dlUrl] : [])
      ]);
    }
  };

  const isFileVideo = useMemo(
    () => state.selected && isVideo(getExtension(state.selected.name || '')),
    [state.selected]
  );

  const onDownloadClick = async () => {
    if (!state.selected) return;

    setDownloading(true);
    const dlUrl = `${state.selected.dlUrl}?download=file`;

    try {
      if (/sharepoint\.com|api\/certificates/.test(dlUrl)) {
        doDownload(dlUrl);
      } else {
        const fileData = await fetchUrl(dlUrl, signal);
        const fileType = state.selected.dlUrl?.endsWith('.pdf')
          ? 'application/pdf'
          : 'image/jpeg';
        const fileContent =
          fileData?.fileBase64 ??
          (typeof fileData === 'string' ? fileData : '');

        fileDownload(document, fileContent, state.selected.name, fileType);
      }
    } finally {
      setTimeout(() => setDownloading(false), 1000);
    }
  };

  useEffect(() => {
    const doCheckUrl = async () => {
      if (!state.selected) return;

      const { url, type } = state.selected;
      const resp = await urlCheck(url);
      const isValid = !!resp;

      setLinkState({ valid: isValid, checked: true });

      if (isValid) {
        const contentType = resp?.headers['content-type'];
        setFileType(type || getFileTypeFromMime(contentType));
        addToValidCache();
      } else {
        setLoading(false);
      }

      if (!deviceCheck.isAboveTablet) {
        setSelected(state.selected);
      }
    };

    setLoading(true);
    if (checkUrl && state.selected?.url) {
      if (!isVerified()) {
        doCheckUrl();
      } else {
        setLinkState({ valid: true, checked: true });
      }
    } else if (state.selected) {
      setLinkState({ valid: true, checked: true });
      setFileType(state.selected.type || '');
      if (state.selected.type !== FileTypes.POWERPOINT) {
        setLoading(false);
      }
    }
  }, [checkUrl, deviceCheck.isAboveTablet, state.selected, isVerified]);

  useEffect(() => () => controller.abort(), [controller]);

  const { checked, valid } = linkState;

  return (
    <Context.Provider
      value={{
        ...state,
        fileType,
        checked,
        valid,
        loading,
        numPages,
        isFullScreen,
        isImage: fileType === FileTypes.IMAGE,
        isVideo: isFileVideo,
        downloading,
        onDownloadClick,
        handleLoad,
        onDocumentLoadSuccess,
        toggleFullScreen,
        canPreview: canPreview(fileType),
        showFilename,
        showDownload,
        showCrossmark,
        showFullscreenButton,
        showIframeToolbar,
        closeViewer: () => {
          setIsFullScreen(false);
          closeViewer();
        }
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useViewFile = () => useContext(Context);
