import type { Size } from '@components/Kpi/Item/style';
import { useVessels } from '@context/Vessels';
import { getCurrencyLocale, isCurrencyCode } from '@utils/currency';
import SC from './style';
import type { NullableKPI } from './types';

const useViewModel = (
  title: string,
  tooltip?: string,
  kpi?: NullableKPI,
  disable = false,
  showArrow = true,
  showBody = true,
  size: Size = 'default',
  showZero = false
) => {
  const {
    state: { vessel }
  } = useVessels();
  const locale = vessel?.locale;

  // Utility: Safely parse numeric values
  const parseNumericValue = (value: unknown): number | null => {
    if (typeof value === 'number') return value;
    if (typeof value === 'string') {
      const parsed = parseFloat(value);
      return isNaN(parsed) ? null : parsed;
    }
    return null;
  };

  // Utility: Format KPI value
  const formatValue = (
    value: unknown,
    unit: string,
    locale?: string
  ): string => {
    const numericValue = parseNumericValue(value);
    if (numericValue === null) return '-';
    const absValue = Math.abs(numericValue);

    switch (unit) {
      case '$':
        return Math.round(absValue).toLocaleString(locale);
      case '%':
        return `${absValue.toLocaleString('en')}%`;
      case 'tonnes':
        return `${absValue.toLocaleString('en')} t`;
      case 't/d':
        return `${absValue.toLocaleString('en')} t/d`;
      default:
        if (isCurrencyCode(unit)) {
          return Math.round(absValue).toLocaleString(getCurrencyLocale(unit));
        }
        return absValue.toString();
    }
  };

  // Utility: Create body display
  const getBody = (
    value: unknown,
    isNegative: boolean,
    isCurrency: boolean
  ) => {
    const displayValue = formatValue(value, kpi?.valueUnit || '', locale);
    return isNegative && isCurrency ? (
      <SC.RedBody>{displayValue}</SC.RedBody>
    ) : (
      displayValue
    );
  };

  // Handle missing KPI
  if (!kpi || (!kpi.value && !kpi.subValue && !kpi.footerValue)) {
    return {
      itemProps: {
        title,
        tooltip,
        disable,
        size,
        body: showZero ? '0' : '-'
      },
      footer: null,
      footerExt: null
    };
  }

  // Extract KPI fields
  const {
    value,
    valueUnit,
    subValue,
    subValueLabel,
    subValueLabelUnit,
    footerLabel,
    footerValue
  } = kpi;

  const numericValue = parseNumericValue(value);
  const isNegative = numericValue !== null && numericValue < 0;
  const isCurrency = valueUnit === '$' || isCurrencyCode(valueUnit);
  const body = getBody(value, isNegative, isCurrency);

  // Construct itemProps
  const itemProps = {
    title,
    tooltip,
    body,
    disable,
    size,
    showBody
  };

  // Determine footer and footerExt
  const footer =
    subValue &&
    subValueLabel?.trim() &&
    subValueLabelUnit?.trim() &&
    subValue !== null &&
    subValue !== undefined
      ? {
          showArrow,
          size,
          kpi
        }
      : null;

  const footerExt =
    footerLabel && footerValue
      ? {
          size,
          kpi
        }
      : null;

  return {
    footer,
    footerExt,
    itemProps
  };
};

export default useViewModel;
