import { createElement } from 'react';

import { useFileViewer } from '@angloeastern/react-library';
import { useConfig } from '@context/Config';
import { useViewFile } from '../context';
import DesktopView from './DesktopView';
import Failure from './Failure';
import ImagePreview from './ImagePreview';
import Loader from './Loader';
import MobileView from './MobileView';
import NoPreview from './NoPreview';
import VideoPreview from './VideoPreview';

const useViewModel = () => {
  const { checked, valid, loading, isImage, isVideo, canPreview } =
    useViewFile();
  const {
    state: { show, selected }
  } = useFileViewer();
  const {
    deviceCheck: { isAboveTablet }
  } = useConfig();

  if (!show) return null;

  if (!checked) return createElement(Loader);

  if (!valid) return createElement(Failure);

  if (valid && selected && canPreview) {
    if (loading && !checked) return createElement(Loader);
    return createElement(isAboveTablet ? DesktopView : MobileView);
  }

  if (isImage) return createElement(ImagePreview);

  if (isVideo) return createElement(VideoPreview);

  return createElement(NoPreview);
};

export default useViewModel;
